import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Project IGCP-628 - the Geological Map of Gondwana - nears completion. A workshop
to review the  remaining issues and discuss the path to completion was held at
the State University of Rio de  Janiero (UFRJ) March 19-21. `}</p>
    <p>{`In addition to the resident project team, the workshop was attended by Victor
Ramos from  Argentina, David McCarthy from the UK, Giancarlo Scardia from Brazil
and Colin Reeves. The fit of  the southernmost parts of South America against
southern Africa and Antarctica remains the most  difficult issue with various
competing lines of evidence still failing to find consensus. A new project  to
focus on these issues is being formulated. The picture shows Colin with some of
the project  team in the Gondwana GIS laboratory at UFRJ at the end of the
meeting, with team leader  Professor Renata Schmitt on the left. `}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      